import Header from "./Header";
import Footer from "./Footer";
import image1 from "../images/landsacepe.jpg";

export default function Landscape() {
  return (
    <div>
      <div>
        <Header />
        <div className="h-full">
          <div className="2xl:container 2xl:mx-auto lg:py-2 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div className="flex flex-col lg:flex-row justify-between gap-8">
              <div className="w-full lg:w-5/12 flex flex-col justify-center">
                <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                  Landscape
                </h1>
                <p className="font-normal text-base leading-6 text-gray-600 ">
                  At the heart of our landscape architecture lies a deep respect
                  for the natural environment combined with innovative design.
                  We create outdoor spaces that are not only visually stunning
                  but also functional and sustainable. From serene private
                  gardens to dynamic public parks, our projects are designed to
                  harmonize with their surroundings, offering a seamless blend
                  of nature and architecture. Each project reflects our
                  commitment to enhancing the natural beauty of the landscape
                  while providing a space for people to connect, relax, and
                  thrive. Explore our portfolio to see how we transform ordinary
                  spaces into extraordinary experiences.
                </p>
              </div>
              <div className="w-full lg:w-8/12 ">
                <div>
                  <div className="bg-white pb- rounded-xl">
                    <div>
                      <div>
                        <div className="max-w-3xl mx-auto pb-4 m-4">
                          <div className="grid gap-2 px-2 mx-4">
                            <figure className="relative max-w-2xl transition-all duration-300 cursor-pointer">
                              <img
                                className=" max-w-full rounded-lg mx-auto"
                                src={image1}
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
