import React, { useState } from "react";
import Header from "./Header";
import build8 from "../images/build22.jpg";
import Footer from "./Footer";
import axios from "axios";

export default function Contact() {
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const sendmessage = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading to true
    const data = { name, email, message };
    try {
      const response = await axios.post(
        "https://makbackend.designmak.com/contact",
        data
      );
      alert(response.data.msg);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("Something went wrong. Please try again later.");
      }
    } finally {
      setLoading(false); // Set loading to false
    }
  };
  return (
    <div>
      <Header />
      <section className="pb-12">
        <div class="relative w-full h-96">
          <img
            class="absolute h-full w-full object-cover object-center"
            src={build8}
            alt="nature image"
          />
          <div class="absolute inset-0 h-full w-full "></div>
          <div class="relative pt-28 text-center">
            <h2 class="block antialiased tracking-normal font-sans font-semibold leading-[1.3] text-white mb-4 text-3xl lg:text-4xl">
              Contact Information
            </h2>
            <p class="block antialiased font-sans text-xl font-medium leading-relaxed text-white mb-9">
              Use The Ping on the Location To Find Our Address
            </p>
          </div>
        </div>
        <div class="-mt-16 mb-8 px-8 ">
          <div class="container mx-auto">
            <div class="py-12 flex justify-center rounded-xl border border-white bg-[#09313d] shadow-md shadow-black/5 saturate-200">
              <div class="w-full">
                <iframe
                  width="100%"
                  height="600"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=9.010924,%2038.775254+(Mak%20Design)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="max-w-2xl mx-auto p-4">
            <div class="px-4 sm:px-0">
              <h3 class="text-base font-semibold leading-7 text-gray-900">
                Office Location
              </h3>
            </div>
            <div class="mt-6  p-4 border-t border-gray-100">
              <dl class="divide-y divide-gray-100">
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Location
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    22 St.Ureal Church Ureal Bldg 4<sup>th</sup> floor , Addis
                    Ababa . Ethiopia
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Phone Number
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    +251 943 123 727
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900"></dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    +251 921 767 493
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900"></dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    +251 927 718 900
                  </dd>
                </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    contact@designmak.com
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <form class="max-w-xl mx-auto pt-4 mt-4" onSubmit={sendmessage}>
            <div class="mb-5">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contact Us
              </label>
              <input
                type="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your Email"
                required
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div class="mb-5">
              <input
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                placeholder="Your Name"
                onChange={(e) => setname(e.target.value)}
              />
            </div>
            <div class="mb-5">
              <textarea
                id="message"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Leave Your Message Here..."
                onChange={(e) => setmessage(e.target.value)}
              ></textarea>
            </div>

            <button
              // onClick={sendmessage}
              disabled={loading}
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {loading ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}
