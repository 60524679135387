import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import image1 from "../images/henok11 (2).jpg";
import image2 from "../images/elif.jpg";
import image3 from "../images/dr3.jpg";
import image4 from "../images/b5.jpg";
import image5 from "../images/b1.jpg";

export default function Finishing() {
  const [mainImage, setMainImage] = useState(image1);
  const handleImageClick = (newImage) => {
    setMainImage(newImage);
  };
  return (
    <div>
      <div>
        <Header />
        <div className="h-full">
          <div className="2xl:container 2xl:mx-auto lg:py-2 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div className="flex flex-col lg:flex-row justify-between gap-8">
              <div className="w-full lg:w-5/12 flex flex-col justify-center">
                <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                  Finishing Work
                </h1>
                <p className="font-normal text-base leading-6 text-gray-600 ">
                  Our Finishing work ensures every project is polished to
                  perfection. We focus on the final details that bring a design
                  to life, from precise carpentry and elegant fixtures to
                  flawless paint and fine materials. Our commitment to quality
                  craftsmanship guarantees that every space exudes
                  sophistication and refinement. Explore our portfolio to see
                  how our meticulous finishing touches elevate each project.
                </p>
              </div>
              <div className="w-full lg:w-8/12 ">
                <div>
                  <div className="bg-white pb- rounded-xl">
                    <div>
                      <div>
                        <div className="max-w-3xl mx-auto ">
                          <div className="grid gap-2 px-2 mx-4">
                            <figure className="relative max-w-2xl transition-all duration-300 cursor-pointer">
                              <img
                                className=" max-w-full rounded-lg mx-auto"
                                src={mainImage}
                                alt=""
                              />
                              <figcaption class="absolute px-4 text-lg text-white bottom-6">
                                <p>Click Below Images To See </p>
                              </figcaption>
                            </figure>
                            <div className="grid grid-cols-5 gap-4 max-w-2xl">
                              <div>
                                <img
                                  className="h-auto max-w-full rounded-lg"
                                  src={image2}
                                  alt=""
                                  onClick={() => handleImageClick(image2)}
                                />
                              </div>
                              <div>
                                <img
                                  className="h-auto max-w-full rounded-lg"
                                  src={image3}
                                  alt=""
                                  onClick={() => handleImageClick(image3)}
                                />
                              </div>
                              <div>
                                <img
                                  className="h-auto max-w-full rounded-lg"
                                  src={image4}
                                  alt=""
                                  onClick={() => handleImageClick(image4)}
                                />
                              </div>
                              <div>
                                <img
                                  className="h-auto max-w-full rounded-lg"
                                  src={image5}
                                  alt=""
                                  onClick={() => handleImageClick(image5)}
                                />
                              </div>
                              <div>
                                <img
                                  className="h-auto max-w-full rounded-lg"
                                  src={image1}
                                  alt=""
                                  onClick={() => handleImageClick(image1)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
