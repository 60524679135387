import React from "react";
import Header from "./Header";
import backgroundImage from "../images/portimage2.jpg";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

export default function View() {
  const location = useLocation();
  const { image } = location.state || {};

  if (!image) {
    return <div>No image data available</div>;
  }

  return (
    <div>
      <div className="hidden">
        <h1>{image.category}</h1>
        <img src={image.src} alt={image.alt} />
        <p>Location: {image.location}</p>
        <p>Year: {image.year}</p>
        {/* Add more details as needed */}
      </div>
      <div className="bg-cover bg-center h-screen mb-24 bg-slate-200">
        <div className="h-full">
          <Header />
          <section>
            <div class="relative w-full h-96">
              <div class="relative pt-12 text-center">
                <h2 class="block antialiased tracking-normal font-sans font-semibold leading-[1.3] text-black mb-4 text-3xl lg:text-4xl">
                  {image.name}
                </h2>
              </div>
            </div>
            <div class="-mt-16 mb-2 px-8 ">
              <div class="container mx-auto">
                <div class="py-12 flex justify-center rounded-xl border border-white bg-[#09313d] shadow-md shadow-black/5">
                  <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                    <div>
                      <div class="pt-6">
                        <nav aria-label="Breadcrumb">
                          <ol
                            role="list"
                            class="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8"
                          >
                            <li>
                              <div class="flex items-center">
                                <a
                                  href="/portfolio"
                                  class="mr-2 text-sm font-medium text-gray-50"
                                >
                                  Portfolio
                                </a>
                                <svg
                                  width="16"
                                  height="20"
                                  viewBox="0 0 16 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                  class="h-5 w-4 text-gray-300"
                                >
                                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                </svg>
                              </div>
                            </li>
                            <li>
                              <div class="flex items-center">
                                <a class="uppercase mr-2 text-sm font-medium text-gray-50">
                                  {image.name}
                                </a>
                              </div>
                            </li>
                          </ol>
                        </nav>

                        <div class="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
                          <div class="aspect-h-4 aspect-w-3 m-2 overflow-hidden rounded-lg lg:block">
                            <img
                              src={image.src2}
                              alt="Two each of gray, white, and black shirts laying flat."
                              class="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div class=" lg:grid m-2 lg:grid-cols-1 lg:gap-y-8">
                            <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                              <img
                                src={image.src}
                                alt="Model wearing plain black basic tee."
                                class="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                              <img
                                src={image.src3}
                                alt="Model wearing plain gray basic tee."
                                class="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div class="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
                            <img
                              src={image.src3}
                              alt="Model wearing plain white basic tee."
                              class="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>

                        <div class="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                          <div class="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                            <h1 class="text-2xl font-bold tracking-tight text-gray-50 sm:text-3xl">
                              {image.name}
                            </h1>
                          </div>

                          <div class="mt-4 lg:row-span-3 lg:mt-0">
                            <p class="text-md sm:text-3xl tracking-tight text-gray-50">
                              Year - {image.year}
                            </p>

                            <p class="text-md sm:text-3xl tracking-tight text-gray-50 ">
                              Location - {image.location}
                            </p>
                            <p class="text-md sm:text-3xl tracking-tight text-gray-50 ">
                              <a
                                href={image.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:text-blue-700"
                              >
                                View PDF
                              </a>
                            </p>
                            <div className="flex items-center justify-center "></div>
                          </div>

                          <div class="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                            <div>
                              <h3 class="sr-only">Description</h3>

                              <div class="space-y-6">
                                <p class="text-base text-gray-50">
                                  {image.description}
                                </p>
                              </div>
                            </div>

                            <div class="mt-10 hidden">
                              <h2 class="text-sm font-medium text-gray-50">
                                Details
                              </h2>

                              <div class="mt-4 space-y-6">
                                <p class="text-sm text-gray-50">
                                  The 6-Pack includes two black, two white, and
                                  two heather gray Basic Tees. Sign up for our
                                  subscription service and be the first to get
                                  new, exciting colors, like our upcoming
                                  &quot;Charcoal Gray&quot; limited release.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}
