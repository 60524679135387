import React, { useEffect, useState } from "react";

import one from "../images/complogo/1.jpg";
import two from "../images/complogo/2.jpg";
import three from "../images/complogo/3.jpg";
import four from "../images/complogo/4.jpg";
import five from "../images/complogo/5.jpg";
import six from "../images/complogo/6.jpg";
import seven from "../images/complogo/7.jpg";
import eight from "../images/complogo/8.jpg";
import nine from "../images/complogo/9.jpg";
import ten from "../images/complogo/10.jpg";
import eleven from "../images/complogo/11.jpg";

import commercial from "../images/new01.jpg";
import resident from "../images/kk 2.jpg";
import arch from "../images/arch.png";
import slidertry2 from "../images/kuku.png";
import slidertry3 from "../images/dadds.png";
import hotel from "../images/dag.png";

export default function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const slides = [
    { image: commercial, category: "Commercial" },
    { image: resident, category: "Residential" },
    { image: arch, category: "Architectural" },
    { image: slidertry2, category: "Appartment" },
     { image: slidertry3, category: "Office and Studio" },
    { image: hotel, category: "Hotel" },
  ];
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };
  // companys
  const companyimages = [
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    one,
    two,
    three,
    four,
  ];
  const [curIndex, setCurIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurIndex((prevIndex) => (prevIndex + 1) % companyimages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [companyimages.length]);

  const displayedImages = [
    ...companyimages.slice(curIndex, curIndex + 5),
    ...companyimages.slice(0, Math.max(0, curIndex + 5 - companyimages.length)),
  ];
  return (
    <div>
      <div>
        <div class="bg-white py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 class="text-center text-4xl font-semibold leading-8 text-gray-900">
              Trusted By The Most Innovative Groups
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5 overflow-hidden">
              {displayedImages.map((src, index) => (
                <img
                  key={index}
                  className="rounded-full col-span-2 max-h-24 w-full object-contain lg:col-span-1 transition-transform duration-500 ease-in-out"
                  src={src}
                  alt={`Logo ${index}`}
                  width="158"
                  height="48"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="xl:mx-auto xl:container py-20 2xl:px-0 px-6">
        <div className="lg:flex items-center justify-between">
          <div className=" lg:w-1/2 w-full">
            <h1
              role="heading"
              className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800"
            >
              Premier Creative Consultant
            </h1>
            <p
              role="contentinfo"
              className="text-base leading-5 mt-5 text-gray-600"
            >
              We’re offering innovative solutions and guidance in the creative
              realm. It epitomizes unparalleled excellence and expertise,
              setting the standard for quality and ingenuity in construction
              consulting services.
            </p>
          </div>
          <div
            className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8"
            role="list"
          >
            <img
              src="https://i.ibb.co/0n6DSS3/bgimg.png"
              className="absolute w-full -ml-12 mt-24"
              alt="background circle images"
            />
            <div
              role="listitem"
              className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30"
            >
              <div className="md:flex items-center justify-between">
                <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                  Embrace Innovation
                </h2>
              </div>
            </div>
            <div
              role="listitem"
              className="bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30"
            >
              <div className="w-2.5  h-auto bg-[#104454] rounded-tl-md rounded-bl-md" />
              <div className="w-full p-8">
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    Crafting Unparalleled Designs
                  </h2>
                </div>
              </div>
            </div>
            <div
              role="listitem"
              className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30 mt-7"
            >
              <div className="md:flex items-center justify-between">
                <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                  Excelling in Precision
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#80f4ff] to-[#053983] opacity-30"
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
          />
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#203acf] to-[#0d7eda] opacity-30"
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-sm leading-6 text-gray-900">
            <strong className="font-semibold">MAK</strong>
            <svg
              viewBox="0 0 2 2"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            MAK Delivers Expert Consulting Services Tailored To Meet The
            Specific Needs Of Our Clients.
          </p>
          <a
            href="/portfolio"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Explore More<span aria-hidden="true">&rarr;</span>
          </a>
        </div>
        <div className="flex flex-1 justify-end"></div>
      </div>
      <div class="bg-white">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h1 class="mb-4 text-3xl font-medium text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
              <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-pink-400">
                Recent
              </span>{" "}
              Works
            </h1>
            <p class="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
              Here at MAC we focus on innovation.
            </p>

            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:space-y-0">
              {slides
                .slice(currentIndex, currentIndex + 2)
                .map((slide, index) => (
                  <div key={index} className="group relative">
                    <div className="relative h-full w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-94">
                      <img
                        src={slide.image}
                        alt={`Image ${index}`}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <p className="text-base font-semibold text-gray-900">
                      {slide.category}
                    </p>
                  </div>
                ))}
            </div>

            <div class="m-4 flex justify-center items-center pt-4">
              <button
                type="button"
                onClick={prevSlide}
                class="flex justify-center items-center me-4 h-full cursor-pointer group focus:outline-none"
                data-carousel-prev
              >
                <span class="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                  <svg
                    class="rtl:rotate-180 w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 5H1m0 0 4 4M1 5l4-4"
                    />
                  </svg>
                  <span class="sr-only">Previous</span>
                </span>
              </button>
              <button
                type="button"
                onClick={nextSlide}
                class="flex justify-center items-center h-full cursor-pointer group focus:outline-none"
                data-carousel-next
              >
                <span class="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                  <svg
                    class="rtl:rotate-180 w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                  <span class="sr-only">Next</span>
                </span>
              </button>
            </div>
            <div class="m-4 flex justify-center items-center pt-4">
              <button
                type="button"
                class="text-white bg-gradient-to-br from-pink-500  to-[#104454] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                <a href="/portfolio">View All</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
