import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PhoneIcon } from "@heroicons/react/20/solid";
import logo from "../images/logo.jpg";

import adil1 from "../images/adil1.png";
import adil2 from "../images/adil2.png";
import adil3 from "../images/adil3.png";
import adil4 from "../images/adil4.png";

import bin1 from "../images/bin1.png";
import bin2 from "../images/bin2.png";
import bin3 from "../images/bin3.png";
import bin4 from "../images/bin4.png";

import gech1 from "../images/gech1.png";
import gech2 from "../images/gech2.png";
import gech3 from "../images/gech3.png";
import gech4 from "../images/gech4.png";

import henok1 from "../images/henok1.png";
import henok2 from "../images/henok2.png";
import henok3 from "../images/henok3.png";
import henok4 from "../images/henok4.png";

import henokam1 from "../images/henokam1.png";
import henokam2 from "../images/henokam2.png";
import henokam3 from "../images/henokam3.png";
import henokam4 from "../images/henokam4.png";

import mak1 from "../images/dag.png";
import mak2 from "../images/arch.png";
import mak3 from "../images/mak3.png";
import mak4 from "../images/mak4.png";

import brk1 from "../images/brk1.png";
import brk2 from "../images/brk2.png";
import brk3 from "../images/br3.png";
import brk4 from "../images/br4.png";

import ahir1 from "../images/ahri1.png";
import ahir2 from "../images/ahri2.png";
import ahir3 from "../images/ahri3.png";
import ahir4 from "../images/ahri4.png";

import air1 from "../images/air1.png";
import air2 from "../images/air2.png";
import air3 from "../images/air3.png";
import air4 from "../images/air4.png";

import tr1 from "../images/tr1.png";
import tr2 from "../images/tr2.png";
import tr3 from "../images/tr3.png";
import tr4 from "../images/tr4.png";

import elif1 from "../images/elif1.png";
import elif2 from "../images/elif2.png";
import elif3 from "../images/elif3.png";
import elif4 from "../images/elif4.png";

import gax1 from "../images/gax1.png";
import gax2 from "../images/gax2.png";
import gax3 from "../images/gax3.png";
import gax4 from "../images/gax4.png";

import hahu1 from "../images/hahu1.png";
import hahu2 from "../images/hahu2.png";
import hahu3 from "../images/hahu3.png";
import hahu4 from "../images/hahu4.png";

import wag1 from "../images/wag1.png";
import wag2 from "../images/wag2.png";
import wag3 from "../images/wag3.png";
import wag4 from "../images/wag4.png";

import selam1 from "../images/selam1.png";
import selam2 from "../images/selam2.png";
import selam3 from "../images/selam3.png";
import selam4 from "../images/selam4.png";

import she1 from "../images/she1.png";
import she2 from "../images/she2.png";
import she3 from "../images/she3.png";
import she4 from "../images/she4.png";

import tame1 from "../images/tame1.png";
import tame2 from "../images/tame2.png";
import tame3 from "../images/tame3.png";
import tame4 from "../images/tame4.png";

import teddy1 from "../images/teddy1.png";
import teddy2 from "../images/teddy2.png";
import teddy3 from "../images/teddy3.png";
import teddy4 from "../images/teddy4.png";

import tofik1 from "../images/tofik1.png";
import tofik2 from "../images/tofik2.png";
import tofik3 from "../images/tofik3.png";
import tofik4 from "../images/tofix4.png";

import dani1 from "../images/dani1.png";
import dani2 from "../images/dani2.png";
import dani3 from "../images/dani3.png";
import dani4 from "../images/dani4.png";

import kbd1 from "../images/kb1.png";
import kbd2 from "../images/kbd2.png";
import kbd3 from "../images/kbd3.png";
import kbd4 from "../images/kbd4.png";

import kebede1 from "../images/kebede1.png";
import kebede2 from "../images/kebede2.png";
import kebede3 from "../images/kebede3.png";
import kebede4 from "../images/kebede4.png";

import kef1 from "../images/kef1.png";
import kef2 from "../images/kef2.png";
import kef3 from "../images/kef3.png";
import kef4 from "../images/kef4.png";

import heno1 from "../images/heno1.png";
import heno2 from "../images/heno2.png";
import heno3 from "../images/heno3.png";
import heno4 from "../images/heno4.png";

import laun1 from "../images/laun1.png";
import laun2 from "../images/laun2.png";
import laun3 from "../images/laun3.png";
import laun4 from "../images/laun4.png";

import res1 from "../images/res1.png";
import res2 from "../images/res2.png";
import res3 from "../images/res3.png";
import res4 from "../images/res4.png";

import se1 from "../images/se1.png";
import se2 from "../images/se2.png";
import se3 from "../images/se3.png";
import se4 from "../images/se4.png";

import yt1 from "../images/yt1.png";
import yt2 from "../images/yt2.png";
import yt3 from "../images/yt3.png";
import yt4 from "../images/yt4.png";
import { NavLink } from "react-router-dom";

const products = [
  {
    name: "Interior Design",
    description: "Functional spaces through thoughtful arrangement",
    href: "/interior",
  },
  {
    name: "Finishing Work",
    description: "Adding final touches",
    href: "/finishing",
  },
  {
    name: "Architectural Design",
    description: "Crafting design for buildings",
    href: "/architectural",
  },
  {
    name: "Consulting",
    description: "Providing expert advice and guidance",
    href: "/consulting",
  },
  {
    name: "Supervision",
    description: "Overseeing & managing projects to ensure the progress",
    href: "/supervision",
  },
  {
    name: "Landscape Design",
    description: "Crafting outdoor spaces for beauty & function.",
    href: "/landscape",
  },
];
const service = [
  {
    name: "Interior Design",
    description: "Functional spaces through thoughtful arrangement",
    href: "/interior",
  },
  {
    name: "Finishing Work",
    description: "Adding final touches",
    href: "/finishing",
  },
  {
    name: "Architectural Design",
    description: "Crafting design for buildings",
    href: "/architectural",
  },
  {
    name: "Consulting",
    description: "Providing expert advice and guidance",
    href: "/consulting",
  },
  {
    name: "Supervision",
    description: "Overseeing & managing projects to ensure the progress",
    href: "/supervision",
  },
  {
    name: "Landscape Design",
    description: "Crafting outdoor spaces for beauty & function.",
    href: "/landscape",
  },
];
const callsToAction = [
  { name: "Sample Videos", href: "/videos", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const images = [
    {
      src: air1,
      src1: air2,
      src2: air3,
      src3: air4,
      name: "AIRLINES ASSOCIATION Office Interior",
      alt: "Airline office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Airlines.pdf",
    },
    {
      src: se1,
      src1: se2,
      src2: se3,
      src3: se4,
      name: "Seife Mall ",
      alt: "Seifu Mall",
      category: "Office",
      description:
        "The Seife Mall features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "--",
      year: "2023",
      link: "https://designmak.com/seife.pdf",
    },
    {
      src: gech1,
      src1: gech2,
      src2: gech3,
      src3: gech4,
      name: "Residential Interior",
      alt: "Henok Amare",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      link: "https://designmak.com/HenokAmare.pdf",
      year: "2022",
    },
    {
      src: bin1,
      src1: bin2,
      src2: bin3,
      src3: bin4,
      name: "Residential House",
      alt: "Binyam Residence",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      link: "https://designmak.com/biniam.pdf",
      year: "2022",
    },
    {
      src: mak1,
      src1: mak2,
      src2: mak3,
      src3: mak4,
      name: "Apartment",
      alt: "Mak",
      category: "Apartment",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/MAK.pdf",
    },
    {
      src: mak2,
      src1: mak1,
      src2: mak3,
      src3: mak4,
      name: "Hotel & Resorts",
      alt: "Mak",
      category: "Hotel & Resorts",
      description:
        "This luxurious hotel and resort blends modern elegance with natural beauty. Inside, guests are greeted by a grand lobby with high ceilings and opulent chandeliers. Rooms feature premium bedding and stunning views. Amenities include a chic lounge, gourmet restaurant, spa, and fitness center.The exterior showcases contemporary architecture amidst lush gardens and landscaped lawns. Guests can enjoy an inviting pool area, private cabanas, and panoramic views from terraces and balconies. Outdoor seating areas and walking paths enhance the serene environment.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/MAK.pdf",
    },
    {
      src: yt1,
      src1: yt2,
      src2: yt3,
      src3: yt4,
      name: "YT Resorts",
      alt: "yt",
      category: "Hotel & Resorts",
      description:
        "This luxurious resort blends modern elegance with natural beauty. Inside, guests are greeted by a grand lobby with high ceilings and opulent chandeliers. Rooms feature premium bedding and stunning views. Amenities include a chic lounge, gourmet restaurant, spa, and fitness center.The exterior showcases contemporary architecture amidst lush gardens and landscaped lawns. Guests can enjoy an inviting pool area, private cabanas, and panoramic views from terraces and balconies. Outdoor seating areas and walking paths enhance the serene environment.",
      location: "--",
      year: "2024",
      link: "https://designmak.com/ytresort.pdf",
    },
    {
      src: laun1,
      src1: laun2,
      src2: laun3,
      src3: laun4,
      name: "Modern Launge",
      alt: "launge design",
      category: "Hotel & Resorts",
      description:
        "This luxurious modern launge blends modern elegance with natural beauty. Inside, guests are greeted by a grand lobby with high ceilings and opulent chandeliers. Rooms feature premium bedding and stunning views. Amenities include a chic lounge, gourmet restaurant, spa, and fitness center.The exterior showcases contemporary architecture amidst lush gardens and landscaped lawns. Guests can enjoy an inviting pool area, private cabanas, and panoramic views from terraces and balconies. Outdoor seating areas and walking paths enhance the serene environment.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/launge.pdf",
    },
    {
      src: dani1,
      src1: dani2,
      src2: dani3,
      src3: dani4,
      name: "Apartment",
      alt: "Daniel",
      category: "Apartments",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/daniel.pdf",
    },
    {
      src: hahu1,
      src1: hahu2,
      src2: hahu3,
      src3: hahu4,
      name: "Hahu Cloud Office",
      alt: "hahu Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Hahu.pdf",
    },
    {
      src: henokam1,
      src1: henokam2,
      src2: henokam3,
      src3: henokam4,
      name: "Residential Interior Design",
      alt: "henok amare",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2023",
      link: "https://designmak.com/HenokAmare.pdf",
    },
    {
      src: res1,
      src1: res2,
      src2: res3,
      src3: res4,
      name: "Residence",
      alt: "residence",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2023",
      link: "https://designmak.com/residence.pdf",
    },
    {
      src: wag1,
      src1: wag2,
      src2: wag3,
      src3: wag4,
      name: "Wagwago trading plc",
      alt: "wagwago amare",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/Wagwagoe.pdf",
    },
    {
      src: henok1,
      src1: henok2,
      src2: henok3,
      src3: henok4,
      name: "Apartment Houses",
      alt: "henok app",
      category: "Apartment",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/henokapp.pdf",
    },
    {
      src: tr1,
      src1: tr2,
      src2: tr3,
      src3: tr4,
      name: "BREAKTHROUGH TRADING S.C",
      alt: "BREAKTHROUGH Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Breakthrough.pdf",
    },
    {
      src: selam1,
      src1: selam2,
      src2: selam3,
      src3: selam4,
      name: "Apartment Interior Design",
      alt: "selamawit apartment",
      category: "Apartment",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Selamawit.pdf",
    },
    {
      src: elif1,
      src1: elif2,
      src2: elif3,
      src3: elif4,
      name: "ELIF MENO PLC",
      alt: "elif meno Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Elif.pdf",
    },
    {
      src: ahir1,
      src1: ahir2,
      src2: ahir3,
      src3: ahir4,
      name: "Office Interior",
      alt: "ahri office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Ahri.pdf",
    },
    {
      src: teddy1,
      src1: teddy2,
      src2: teddy3,
      src3: teddy4,
      name: "Residential Interior",
      alt: "teddy ",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/teddy.pdf",
    },
    {
      src: heno1,
      src1: heno2,
      src2: heno3,
      src3: heno4,
      name: "Residential Interior",
      alt: "Henok Amare",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/henok.pdf",
    },
    {
      src: kbd1,
      src1: kbd2,
      src2: kbd3,
      src3: kbd4,
      name: "Luxury Residence",
      alt: "kebede",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/kebede1.pdf",
    },
    {
      src: kebede1,
      src1: kebede2,
      src2: kebede3,
      src3: kebede4,
      name: "Modern Luxury Residence",
      alt: "kebede Modern",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/kebede2.pdf",
    },
    {
      src: adil1,
      src1: adil2,
      src2: adil3,
      src3: adil4,
      name: "Residential Houses",
      alt: "Adil",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Adilresidence.pdf",
    },
    {
      src: tofik1,
      src1: tofik2,
      src2: tofik3,
      src3: tofik4,
      name: "Residential Houses",
      alt: "tofik",
      category: "residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/tofik.pdf",
    },
    {
      src: she1,
      src1: she2,
      src2: she3,
      src3: she4,
      name: "Residential INTERIOR DESIGN",
      alt: "shemsu",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Shemsu.pdf",
    },
    {
      src: tame1,
      src1: tame2,
      src2: tame3,
      src3: tame4,
      name: "Residential INTERIOR DESIGN",
      alt: "tame",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Tame.pdf",
    },
    {
      src: gax1,
      src1: gax2,
      src2: gax3,
      src3: gax4,
      name: "G-Ax (BREAKTH. S.C) Office",
      alt: "G-AX Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/G-Ax.pdf",
    },
    {
      src: brk1,
      src1: brk2,
      src2: brk3,
      src3: brk4,
      name: "Mexico Breakthrough Office Interior",
      alt: "mexico breakthrough",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Mexico.pdf",
    },
    {
      src: kef1,
      src1: kef2,
      src2: kef3,
      src3: kef4,
      name: "Aware Studio & Office",
      alt: "Aware",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/AWARE1.pdf",
    },
    // Add more images as needed
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  useEffect(() => {
    // Filter items based on the search query
    const results = images.filter((image) =>
      image.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(results);
  }, [searchQuery]);

  const handleItemClick = () => {
    setSearchQuery("");
  };
  return (
    <div>
      <header>
        <nav
          className="p-2 mx-auto flex max-w-full items-center justify-between py-4 lg:pl-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="m-2 h-8 w-auto" src={logo} alt="" />
            </a>
            <p className="text-xl hidden">Mak Interior and architect </p>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <PopoverGroup className="hidden lg:flex lg:gap-x-12">
            <a
              href="/"
              className="text-lg font-semibold leading-6 text-black"
            >
              Home
            </a>
            <a
              href="/portfolio"
              className="text-lg font-semibold leading-6 text-black"
            >
              Portfolio
            </a>

            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-black">
                Service
                <ChevronDownIcon
                  className="h-6 w-6 flex-none text-gray-900"
                  aria-hidden="true"
                />
              </PopoverButton>

              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="p-4">
                    {service.map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900"
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    {callsToAction.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                      >
                        <item.icon
                          className="h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>

            <a
              href="/about"
              className="text-lg font-semibold leading-6 text-black"
            >
              About Us
            </a>
            <a
              href="/contact"
              className="text-lg font-semibold leading-6 text-black"
            >
              Contact Us
            </a>
          </PopoverGroup>
          <div className="flex-1 hidden lg:flex lg:flex-1 lg:justify-end">
            <form class="flex items-center max-w-sm mx-auto">
              <label for="simple-search" class="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-emerald-500 focus:border-emerald-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-emerald-500 dark:focus:border-emerald-500"
                  placeholder="Search ..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery && (
                  <ul className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg divide-y divide-gray-200 z-10">
                    {filteredItems.map((image) => (
                      <li
                        key={image.id}
                        onClick={handleItemClick}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <NavLink state={{ image }} to="/View">
                          {image.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <button class="hidden p-2.5 ms-2 text-sm font-medium text-white bg-[#09313d] rounded-lg border border-[#09313d] hover:bg-[#09313d] focus:ring-4 focus:outline-none focus:ring-[#09313d] dark:bg-[#09313d] dark:hover:bg-[#09313d] dark:focus:ring-[#09313d]">
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span class="sr-only">Search</span>
              </button>
            </form>
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src={logo} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>
                  <a
                    href="/portfolio"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Portfolio
                  </a>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Service
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">
                          {[...products, ...callsToAction].map((item) => (
                            <DisclosureButton
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </DisclosureButton>
                          ))}
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>

                  <a
                    href="/about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    About Us
                  </a>
                  <a
                    href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact
                  </a>
                </div>
                <div className="py-6">
                  <form class="flex items-center max-w-sm mx-auto">
                    <label for="simple-search" class="sr-only">
                      Search
                    </label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                      <input
                        type="text"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search ..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      {searchQuery && (
                        <ul className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg divide-y divide-gray-200 z-10">
                          {filteredItems.map((image) => (
                            <li
                              key={image.id}
                              onClick={handleItemClick}
                              className="p-2 hover:bg-gray-100 cursor-pointer"
                            >
                              <NavLink state={{ image }} to="/View">
                                {image.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <button
                      type="submit"
                      class="hidden p-2.5 ms-2 text-sm font-medium text-white bg-emerald-600 rounded-lg border border-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span class="sr-only">Search</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
};
export default Header;
