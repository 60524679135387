import Header from "./Header";
import Footer from "./Footer";
import image1 from "../images/portimage.jpg";

export default function Supervision() {
  return (
    <div>
      <div>
        <Header />
        <div className="h-full">
          <div className="2xl:container 2xl:mx-auto lg:py-2 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div className="flex flex-col lg:flex-row justify-between gap-8">
              <div className="w-full lg:w-5/12 flex flex-col justify-center">
                <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                  Supervision
                </h1>
                <p className="font-normal text-base leading-6 text-gray-600 ">
                  Our supervision services ensure every project meets the
                  highest standards of quality and precision. From start to
                  finish, we oversee each phase of construction, working closely
                  with all involved to ensure designs are executed flawlessly,
                  on time, and within budget. Experience how our hands-on
                  approach transforms visionary designs into stunning realities.
                </p>
              </div>
              <div className="w-full lg:w-8/12 ">
                <div>
                  <div className="bg-white pb- rounded-xl">
                    <div>
                      <div>
                        <div className="max-w-3xl mx-auto pb-4 m-4">
                          <div className="grid gap-2 px-2 mx-4">
                            <figure className="relative max-w-2xl transition-all duration-300 cursor-pointer">
                              <img
                                className=" max-w-full rounded-lg mx-auto"
                                src={image1}
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
