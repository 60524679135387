import React from "react";
import Header from "./Header";
import build8 from "../images/build22.jpg";
import Footer from "./Footer";
import team from "../images/team.jpg";

export default function Aboutus() {
  return (
    <div>
      <div>
        <Header />
        <section>
          <div class="relative w-full h-96">
            <img
              class="absolute h-full w-full object-cover object-center"
              src={build8}
              alt="nature image"
            />

            <div class="relative pt-28 text-center">
              <h2 class="block antialiased tracking-normal font-sans font-semibold leading-[1.3] text-white mb-4 text-3xl lg:text-4xl">
                About Us
              </h2>
            </div>
          </div>
          <div class="-mt-16 mb-8 px-8 ">
            <div class="container mx-auto">
              <div class="py-12 flex justify-center rounded-xl border border-white bg-[#09313d] shadow-md shadow-black/5 saturate-100">
                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                  <div className="flex flex-col lg:flex-row justify-between gap-8">
                    <div className="w-full lg:w-5/12 flex flex-col justify-center">
                      <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-50 pb-4">
                        About Us
                      </h1>
                      <p className="font-normal text-base leading-6 text-gray-100 ">
                        Welcome to MAK , where creativity meets functionality in
                        the heart of Ethiopia. Specializing in both interior and
                        exterior design, we transform spaces with innovative
                        solutions tailored to your unique vision. Our team of
                        skilled architects and designers brings together a
                        wealth of experience and a passion for excellence,
                        ensuring that every project, whether residential or
                        commercial, reflects the perfect blend of aesthetics and
                        practicality.
                      </p>
                    </div>
                    <div className="w-full lg:w-8/12 ">
                      <img
                        className="w-full h-full rounded-lg"
                        src={team}
                        alt="A group of People"
                      />
                    </div>
                  </div>

                  <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                    <div className="w-full lg:w-5/12 flex flex-col justify-center">
                      <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-50 pb-4">
                        Meet The Team
                      </h1>
                    </div>
                    <div className="w-full lg:w-8/12 lg:pt-8">
                      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                        <div className="p-4 pb-6 flex justify-center flex-col items-center">
                          <p className="text-white">General Manager</p>
                          <p className="font-medium text-xl leading-5 text-gray-50 mt-4">
                            Anduamlak
                          </p>
                        </div>
                        <div className="p-4 pb-6 flex justify-center flex-col items-center">
                          <p className="text-white">
                            Duputy Manager (Finance Head)
                          </p>
                          <p className="font-medium text-xl leading-5 text-gray-50 mt-4">
                            Kedir
                          </p>
                        </div>
                        <div className="p-4 pb-6 flex justify-center flex-col items-center">
                          <p className="text-white">
                            Project Manger & Supervisor
                          </p>
                          <p className="font-medium text-xl leading-5 text-gray-50 mt-4">
                            Henok
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                    <div className="w-full lg:w-5/12 flex flex-col justify-center">
                      <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-50 pb-4">
                        Our Vision
                      </h1>
                    </div>
                    <div className="w-full lg:w-8/12 lg:pt-8">
                      <p className="font-normal text-base leading-6 text-gray-50 ">
                        To be Ethiopia's leading design and architecture firm,
                        setting the benchmark for creativity and innovation in
                        transforming spaces.
                      </p>
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                    <div className="w-full lg:w-5/12 flex flex-col justify-center">
                      <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-50 pb-4">
                        Our Mission
                      </h1>
                    </div>
                    <div className="w-full lg:w-8/12 lg:pt-8">
                      <p className="font-normal text-base leading-6 text-gray-50 ">
                        Our mission is to create aesthetically stunning and
                        highly functional designs that reflect our clients'
                        unique needs and aspirations. We strive to deliver
                        exceptional service through innovative solutions,
                        sustainable practices, and a commitment to excellence in
                        every project we undertake.
                      </p>
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
                    <div className="w-full lg:w-5/12 flex flex-col justify-center">
                      <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-50 pb-4">
                        Our Value
                      </h1>
                    </div>
                    <div className="w-full lg:w-8/12 lg:pt-8">
                      <p className="pb-4 font-normal text-base leading-6 text-gray-50 ">
                        <span className="font-semibold">Creativity:</span>We
                        embrace bold ideas and innovative approaches,
                        continuously pushing the boundaries of design to create
                        unique and inspiring spaces.
                      </p>
                      <p className="pb-4 font-normal text-base leading-6 text-gray-50 ">
                        <span className="font-semibold">Integrity: </span>We
                        uphold the highest standards of honesty and
                        professionalism, ensuring transparency and trust in all
                        our client relationships.
                      </p>
                      <p className="pb-4 font-normal text-base leading-6 text-gray-50 ">
                        <span className="font-semibold">Quality: </span>We are
                        dedicated to delivering superior craftsmanship and
                        meticulous attention to detail in every aspect of our
                        work.
                      </p>
                      <p className="pb-4 font-normal text-base leading-6 text-gray-50 ">
                        <span className="font-semibold">Sustainability: </span>
                        We prioritize environmentally conscious practices,
                        aiming to create designs that are not only beautiful but
                        also sustainable and responsible.
                      </p>
                      <p className="pb-4 font-normal text-base leading-6 text-gray-50 ">
                        <span className="font-semibold">Collaboration: </span>We
                        believe in the power of teamwork and foster a
                        collaborative environment where diverse perspectives and
                        talents come together to achieve extraordinary results
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
