import React, { useEffect, useState } from "react";
import image1 from "../images/homee2.jpg";
import image2 from "../images/homeimage.jpg";
import image3 from "../images/im.jpg";
import image4 from "../images/images.jpg";
import image5 from "../images/pexels-pixabay-259962.jpg";
import Footer from "./Footer";
import airline from "../images/airline.jpg";

export default function Body() {
  const [mainImage, setMainImage] = useState(image1);

  const handleImageClick = (newImage) => {
    setMainImage(newImage);
  };

  return (
    <div>
      <div class="bg-white py-12 sm:py-12">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt class="text-base leading-7 text-gray-600">Finshed Project</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                44
              </dd>
            </div>
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt class="text-base leading-7 text-gray-600">Staff</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                41
              </dd>
            </div>
            <div class="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt class="text-base leading-7 text-gray-600">Awesome Clients</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                93
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <dh-component>
        <section className="max-w-8xl mx-auto container bg-white pt-16">
          <div>
            <div role="contentinfo" className="flex items-center flex-col px-4">
              <h1
                tabIndex={0}
                className="focus:outline-none text-4xl lg:text-4xl font-extrabold text-center leading-10 text-gray-800 lg:w-5/12 md:w-9/12 pt-4"
              >
                Our Approach
              </h1>
            </div>
            <div
              tabIndex={0}
              aria-label="group of cards"
              className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4"
            >
              <div
                tabIndex={0}
                aria-label="card 1"
                className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
              >
                {" "}
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-[#104454] rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p>1</p>
                  </div>
                </div>
                <div className="w-10/12">
                  <h2
                    tabIndex={0}
                    className="focus:outline-none text-lg font-bold leading-tight text-gray-800"
                  >
                    Conceptual Design
                  </h2>
                  <p
                    tabIndex={0}
                    className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
                  >
                    We start by gathering information from you and conducting
                    field surveys to develop two to three initial design options
                    for your review.
                  </p>
                </div>
              </div>
              <div
                tabIndex={0}
                aria-label="card 2"
                className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
              >
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-[#104454] rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p>2</p>
                  </div>
                </div>
                <div className="w-10/12">
                  <h2
                    tabIndex={0}
                    className="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                  >
                    Design Refinement
                  </h2>
                  <p
                    tabIndex={0}
                    className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
                  >
                    Based on your selected conceptual design and any feedback,
                    we refine and adjust the design to meet your specific needs.
                  </p>
                </div>
              </div>
              <div
                tabIndex={0}
                aria-label="card 3"
                className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
              >
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-[#104454] rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p>3</p>
                  </div>
                </div>
                <div className="w-10/12">
                  <h2
                    tabIndex={0}
                    className="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                  >
                    Detailed Documentation
                  </h2>
                  <p
                    tabIndex={0}
                    className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
                  >
                    With the final design in place, we prepare comprehensive
                    drawings, notes, and technical specifications required for
                    bidding, construction, and permits.
                  </p>
                </div>
              </div>
              <div
                tabIndex={0}
                aria-label="card 4"
                className="focus:outline-none flex sm:w-full md:w-5/12 pb-20"
              >
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-[#104454] rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <p>4</p>
                  </div>
                </div>
                <div className="w-10/12">
                  <h2
                    tabIndex={0}
                    className="focus:outline-none text-lg font-semibold leading-tight text-gray-800"
                  >
                    Construction Oversight
                  </h2>
                  <p
                    tabIndex={0}
                    className="focus:outline-none text-base text-gray-600 leading-normal pt-2"
                  >
                    We manage the construction process to ensure it aligns with
                    the design intent, conduct site visits, and address any
                    issues that arise on-site.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </dh-component>
      <div className="lg:px-20 md:px-6 px-4 md:py-12 py-8 h-screen bg-gradient-to-r from-cyan-500 to-[#064e64]">
        <div className="lg:flex items-center justify-between">
          <div className="lg:w-1/3">
            <h1 className="text-4xl font-semibold leading-9 text-gray-50">
              What Our Customers Are Saying
            </h1>
            <p className="text-base leading-6 mt-4 text-gray-50 capitalize">
              We are absolutely thrilled with the work done by MAK on our office
              in Addis Ababa. From the initial consultation to the final
              unveiling, their team demonstrated exceptional professionalism,
              creativity, and attention to detail. They understood our vision
              and brought it to life in ways we couldn't have imagined.
            </p>
            <button
              aria-label="view catalogue"
              className="focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 focus:outline-none mt-6 md:mt-8 text-base font-semibold leading-none text-gray-50 flex items-center hover:underline"
            >
              Ethiopia Airlines Association .
            </button>
          </div>
          <div className="lg:w-7/12 lg:mt-0 mt-8">
            <div className="w-full h-full bg-red-200">
              <img src={airline} alt="apartment design" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
