import Home from "./Component/Home";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { createContext } from "react";
import Portfolio from "./Component/Portfolio";
import Contact from "./Component/Contact";
import Aboutus from "./Component/Aboutus";
import View from "./Component/View";
import Service from "./Component/Service";
import Finishing from "./Component/Finishing";
import Architectural from "./Component/Architectural";
import Consulting from "./Component/Consulting";
import Supervision from "./Component/Supervision";
import Landscape from "./Component/Landscape";
import Videos from "./Component/Videos";

export const userContext = createContext();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<Aboutus />} />
      <Route path="/view" element={<View />} />
      <Route path="/interior" element={<Service />} />
      <Route path="/finishing" element={<Finishing />} />
      <Route path="/architectural" element={<Architectural />} />
      <Route path="/consulting" element={<Consulting />} />
      <Route path="/supervision" element={<Supervision />} />
      <Route path="/landscape" element={<Landscape />} />
      <Route path="/videos" element={<Videos />} />
    </Route>
  )
);
function App() {
  return (
    <userContext.Provider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </userContext.Provider>
  );
}

export default App;
