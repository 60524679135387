import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

import image5 from "../images/consult.jpg";

export default function Consulting() {
  return (
    <div>
      <div>
        <Header />
        <div className="h-full">
          <div className="2xl:container 2xl:mx-auto lg:py-2 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div className="flex flex-col lg:flex-row justify-between gap-8">
              <div className="w-full lg:w-5/12 flex flex-col justify-center">
                <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                  Consulting
                </h1>
                <p className="font-normal text-base leading-6 text-gray-600 ">
                  Consulting involves providing expert advice and solutions to
                  organizations or individuals in specific areas such as
                  business, management, finance, or technology. Consultants
                  analyze problems, develop strategies, and help implement
                  changes to improve efficiency, performance, and outcomes.
                </p>
              </div>
              <div className="w-full lg:w-8/12 ">
                <div>
                  <div className="bg-white pb- rounded-xl">
                    <div>
                      <div>
                        <div className="max-w-3xl mx-auto ">
                          <div className="grid gap-2 px-2 mx-4">
                            <figure className="relative max-w-2xl transition-all duration-300 cursor-pointer">
                              <img
                                className=" max-w-full rounded-lg mx-auto"
                                src={image5}
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
