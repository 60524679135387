import { useState } from "react";

import adil1 from "../images/adil1.png";
import adil2 from "../images/adil2.png";
import adil3 from "../images/adil3.png";
import adil4 from "../images/adil4.png";

import bin1 from "../images/bin1.png";
import bin2 from "../images/bin2.png";
import bin3 from "../images/bin3.png";
import bin4 from "../images/bin4.png";

import gech1 from "../images/gech1.png";
import gech2 from "../images/gech2.png";
import gech3 from "../images/gech3.png";
import gech4 from "../images/gech4.png";

import henok1 from "../images/henok1.png";
import henok2 from "../images/henok2.png";
import henok3 from "../images/henok3.png";
import henok4 from "../images/henok4.png";

import henokam1 from "../images/henokam1.png";
import henokam2 from "../images/henokam2.png";
import henokam3 from "../images/henokam3.png";
import henokam4 from "../images/henokam4.png";

import mak1 from "../images/dag.png";
import mak2 from "../images/arch.png";
import mak3 from "../images/mak3.png";
import mak4 from "../images/mak4.png";

import brk1 from "../images/brk1.png";
import brk2 from "../images/brk2.png";
import brk3 from "../images/br3.png";
import brk4 from "../images/br4.png";

import ahir1 from "../images/ahri1.png";
import ahir2 from "../images/ahri2.png";
import ahir3 from "../images/ahri3.png";
import ahir4 from "../images/ahri4.png";

import air1 from "../images/air1.png";
import air2 from "../images/air2.png";
import air3 from "../images/air3.png";
import air4 from "../images/air4.png";

import tr1 from "../images/tr1.png";
import tr2 from "../images/tr2.png";
import tr3 from "../images/tr3.png";
import tr4 from "../images/tr4.png";

import elif1 from "../images/elif1.png";
import elif2 from "../images/elif2.png";
import elif3 from "../images/elif3.png";
import elif4 from "../images/elif4.png";

import gax1 from "../images/gax1.png";
import gax2 from "../images/gax2.png";
import gax3 from "../images/gax3.png";
import gax4 from "../images/gax4.png";

import hahu1 from "../images/hahu1.png";
import hahu2 from "../images/hahu2.png";
import hahu3 from "../images/hahu3.png";
import hahu4 from "../images/hahu4.png";

import wag1 from "../images/wag1.png";
import wag2 from "../images/wag2.png";
import wag3 from "../images/wag3.png";
import wag4 from "../images/wag4.png";

import selam1 from "../images/selam1.png";
import selam2 from "../images/selam2.png";
import selam3 from "../images/selam3.png";
import selam4 from "../images/selam4.png";

import she1 from "../images/she1.png";
import she2 from "../images/she2.png";
import she3 from "../images/she3.png";
import she4 from "../images/she4.png";

import tame1 from "../images/tame1.png";
import tame2 from "../images/tame2.png";
import tame3 from "../images/tame3.png";
import tame4 from "../images/tame4.png";

import teddy1 from "../images/teddy1.png";
import teddy2 from "../images/teddy2.png";
import teddy3 from "../images/teddy3.png";
import teddy4 from "../images/teddy4.png";

import tofik1 from "../images/tofik1.png";
import tofik2 from "../images/tofik2.png";
import tofik3 from "../images/tofik3.png";
import tofik4 from "../images/tofix4.png";

import dani1 from "../images/dani1.png";
import dani2 from "../images/dani2.png";
import dani3 from "../images/dani3.png";
import dani4 from "../images/dani4.png";

import kbd1 from "../images/kb1.png";
import kbd2 from "../images/kbd2.png";
import kbd3 from "../images/kbd3.png";
import kbd4 from "../images/kbd4.png";

import kebede1 from "../images/kebede1.png";
import kebede2 from "../images/kebede2.png";
import kebede3 from "../images/kebede3.png";
import kebede4 from "../images/kebede4.png";

import kef1 from "../images/kef1.png";
import kef2 from "../images/kef2.png";
import kef3 from "../images/kef3.png";
import kef4 from "../images/kef4.png";

import heno1 from "../images/heno1.png";
import heno2 from "../images/heno2.png";
import heno3 from "../images/heno3.png";
import heno4 from "../images/heno4.png";

import laun1 from "../images/laun1.png";
import laun2 from "../images/laun2.png";
import laun3 from "../images/laun3.png";
import laun4 from "../images/laun4.png";

import res1 from "../images/res1.png";
import res2 from "../images/res2.png";
import res3 from "../images/res3.png";
import res4 from "../images/res4.png";

import se1 from "../images/se1.png";
import se2 from "../images/se2.png";
import se3 from "../images/se3.png";
import se4 from "../images/se4.png";

import yt1 from "../images/yt1.png";
import yt2 from "../images/yt2.png";
import yt3 from "../images/yt3.png";
import yt4 from "../images/yt4.png";

import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";

export default function Portfolio() {
  const [selectedTab, setSelectedTab] = useState("All");
  const images = [
    {
      src: air1,
      src1: air2,
      src2: air3,
      src3: air4,
      name: "AIRLINES ASSOCIATION Office Interior",
      alt: "Airline office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Airlines.pdf",
    },
    {
      src: se1,
      src1: se2,
      src2: se3,
      src3: se4,
      name: "Seife Mall ",
      alt: "Seifu Mall",
      category: "Office",
      description:
        "The Seife Mall features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "--",
      year: "2023",
      link: "https://designmak.com/seife.pdf",
    },
    {
      src: gech1,
      src1: gech2,
      src2: gech3,
      src3: gech4,
      name: "Residential Interior",
      alt: "Henok Amare",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      link: "https://designmak.com/HenokAmare.pdf",
      year: "2022",
    },
    {
      src: bin1,
      src1: bin2,
      src2: bin3,
      src3: bin4,
      name: "Residential House",
      alt: "Binyam Residence",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      link: "https://designmak.com/biniam.pdf",
      year: "2022",
    },
    {
      src: mak1,
      src1: mak2,
      src2: mak3,
      src3: mak4,
      name: "Apartment",
      alt: "Mak",
      category: "Apartment",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/MAK.pdf",
    },
    {
      src: mak2,
      src1: mak1,
      src2: mak3,
      src3: mak4,
      name: "Hotel & Resorts",
      alt: "Mak",
      category: "Hotel & Resorts",
      description:
        "This luxurious hotel and resort blends modern elegance with natural beauty. Inside, guests are greeted by a grand lobby with high ceilings and opulent chandeliers. Rooms feature premium bedding and stunning views. Amenities include a chic lounge, gourmet restaurant, spa, and fitness center.The exterior showcases contemporary architecture amidst lush gardens and landscaped lawns. Guests can enjoy an inviting pool area, private cabanas, and panoramic views from terraces and balconies. Outdoor seating areas and walking paths enhance the serene environment.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/MAK.pdf",
    },
    {
      src: yt1,
      src1: yt2,
      src2: yt3,
      src3: yt4,
      name: "YT Resorts",
      alt: "yt",
      category: "Hotel & Resorts",
      description:
        "This luxurious resort blends modern elegance with natural beauty. Inside, guests are greeted by a grand lobby with high ceilings and opulent chandeliers. Rooms feature premium bedding and stunning views. Amenities include a chic lounge, gourmet restaurant, spa, and fitness center.The exterior showcases contemporary architecture amidst lush gardens and landscaped lawns. Guests can enjoy an inviting pool area, private cabanas, and panoramic views from terraces and balconies. Outdoor seating areas and walking paths enhance the serene environment.",
      location: "--",
      year: "2024",
      link: "https://designmak.com/ytresort.pdf",
    },
    {
      src: laun1,
      src1: laun2,
      src2: laun3,
      src3: laun4,
      name: "Modern Launge",
      alt: "launge design",
      category: "Hotel & Resorts",
      description:
        "This luxurious modern launge blends modern elegance with natural beauty. Inside, guests are greeted by a grand lobby with high ceilings and opulent chandeliers. Rooms feature premium bedding and stunning views. Amenities include a chic lounge, gourmet restaurant, spa, and fitness center.The exterior showcases contemporary architecture amidst lush gardens and landscaped lawns. Guests can enjoy an inviting pool area, private cabanas, and panoramic views from terraces and balconies. Outdoor seating areas and walking paths enhance the serene environment.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/launge.pdf",
    },
    {
      src: dani1,
      src1: dani2,
      src2: dani3,
      src3: dani4,
      name: "Apartment",
      alt: "Daniel",
      category: "Apartments",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/daniel.pdf",
    },
    {
      src: hahu1,
      src1: hahu2,
      src2: hahu3,
      src3: hahu4,
      name: "Hahu Cloud Office",
      alt: "hahu Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Hahu.pdf",
    },
    {
      src: henokam1,
      src1: henokam2,
      src2: henokam3,
      src3: henokam4,
      name: "Residential Interior Design",
      alt: "henok amare",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2023",
      link: "https://designmak.com/HenokAmare.pdf",
    },
    {
      src: res1,
      src1: res2,
      src2: res3,
      src3: res4,
      name: "Residence",
      alt: "residence",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2023",
      link: "https://designmak.com/residence.pdf",
    },
    {
      src: wag1,
      src1: wag2,
      src2: wag3,
      src3: wag4,
      name: "Wagwago trading plc",
      alt: "wagwago amare",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/Wagwagoe.pdf",
    },
    {
      src: henok1,
      src1: henok2,
      src2: henok3,
      src3: henok4,
      name: "Apartment Houses",
      alt: "henok app",
      category: "Apartment",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/henokapp.pdf",
    },
    {
      src: tr1,
      src1: tr2,
      src2: tr3,
      src3: tr4,
      name: "BREAKTHROUGH TRADING S.C",
      alt: "BREAKTHROUGH Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Breakthrough.pdf",
    },
    {
      src: selam1,
      src1: selam2,
      src2: selam3,
      src3: selam4,
      name: "Apartment Interior Design",
      alt: "selamawit apartment",
      category: "Apartment",
      description:
        "This apartment interior boasts a contemporary design with a cozy and inviting ambiance. The open-plan living area seamlessly integrates the kitchen, dining, and lounge spaces, creating a cohesive flow. Large windows allow natural light to flood the space, highlighting the elegant finishes and modern furnishings. The kitchen features sleek cabinetry, stainless steel appliances, and a stylish island perfect for casual dining. The bedroom offers a serene retreat with soft, neutral tones and plush textiles. Thoughtful storage solutions and smart use of space enhance functionality, while tasteful decor elements add a touch of personality and warmth.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Selamawit.pdf",
    },
    {
      src: elif1,
      src1: elif2,
      src2: elif3,
      src3: elif4,
      name: "ELIF MENO PLC",
      alt: "elif meno Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Elif.pdf",
    },
    {
      src: ahir1,
      src1: ahir2,
      src2: ahir3,
      src3: ahir4,
      name: "Office Interior",
      alt: "ahri office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Ahri.pdf",
    },
    {
      src: teddy1,
      src1: teddy2,
      src2: teddy3,
      src3: teddy4,
      name: "Residential Interior",
      alt: "teddy ",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/teddy.pdf",
    },
    {
      src: heno1,
      src1: heno2,
      src2: heno3,
      src3: heno4,
      name: "Residential Interior",
      alt: "Henok Amare",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/henok.pdf",
    },
    {
      src: kbd1,
      src1: kbd2,
      src2: kbd3,
      src3: kbd4,
      name: "Luxury Residence",
      alt: "kebede",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/kebede1.pdf",
    },
    {
      src: kebede1,
      src1: kebede2,
      src2: kebede3,
      src3: kebede4,
      name: "Modern Luxury Residence",
      alt: "kebede Modern",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/kebede2.pdf",
    },
    {
      src: adil1,
      src1: adil2,
      src2: adil3,
      src3: adil4,
      name: "Residential Houses",
      alt: "Adil",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Adilresidence.pdf",
    },
    {
      src: tofik1,
      src1: tofik2,
      src2: tofik3,
      src3: tofik4,
      name: "Residential Houses",
      alt: "tofik",
      category: "residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/tofik.pdf",
    },
    {
      src: she1,
      src1: she2,
      src2: she3,
      src3: she4,
      name: "Residential INTERIOR DESIGN",
      alt: "shemsu",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Shemsu.pdf",
    },
    {
      src: tame1,
      src1: tame2,
      src2: tame3,
      src3: tame4,
      name: "Residential INTERIOR DESIGN",
      alt: "tame",
      category: "Residential",
      description:
        "We emphasize sustainability, functionality, and aesthetic appeal, ensuring every home harmonizes with its environment and reflects its inhabitants' unique lifestyles. Explore our collection to discover inspiring, meticulously crafted spaces that blend beauty and livability. Let us help bring your dream home to life",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Tame.pdf",
    },
    {
      src: gax1,
      src1: gax2,
      src2: gax3,
      src3: gax4,
      name: "G-Ax (BREAKTH. S.C) Office",
      alt: "G-AX Office",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/G-Ax.pdf",
    },
    {
      src: brk1,
      src1: brk2,
      src2: brk3,
      src3: brk4,
      name: "Mexico Breakthrough Office Interior",
      alt: "mexico breakthrough",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2022",
      link: "https://designmak.com/Mexico.pdf",
    },
    {
      src: kef1,
      src1: kef2,
      src2: kef3,
      src3: kef4,
      name: "Aware Studio & Office",
      alt: "Aware",
      category: "Office",
      description:
        "The office interior features a modern, open-plan design with abundant natural light. Sleek, minimalist furniture in neutral tones complements the clean lines of the space. Glass partitions create a sense of openness while providing privacy for meeting rooms and private offices. The use of greenery and contemporary artwork adds vibrancy and a touch of nature. Ergonomic workstations and collaborative areas promote a productive and flexible working environment. High ceilings and strategically placed lighting fixtures enhance the overall sense of space and comfort.",
      location: "22",
      year: "2023",
      link: "https://designmak.com/AWARE1.pdf",
    },
    // Add more images as needed
  ];
  const filteredImages =
    selectedTab === "All"
      ? images
      : images.filter((image) => image.category === selectedTab);

  return (
    <div>
      <div className="bg-cover bg-center h-screen mb-24 bg-slate-200">
        <div className="h-full">
          <Header />
          <div>
            <main class="grid min-h-full place-items-center px-6 pt-12 sm:py-12 lg:px-8">
              <div class="text-center">
                <h1 class="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
                  Our Portfolio
                </h1>
                <p class="mt-6 text-base leading-7 text-gray-800">
                  MAK Architects and Interior Design
                </p>
              </div>
            </main>
            {/* tabs */}
            <div>
              <div class="flex items-center justify-center py-4 md:py-8 flex-wrap">
                <button
                  type="button"
                  onClick={() => setSelectedTab("All")}
                  className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 ${
                    selectedTab === "All" ? "bg-gray-700 text-blue-600" : ""
                  }`}
                >
                  All
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("Office")}
                  className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 ${
                    selectedTab === "Office" ? "bg-gray-700 text-blue-600" : ""
                  }`}
                >
                  Office
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("Residential")}
                  className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 ${
                    selectedTab === "Residential"
                      ? "bg-gray-700 text-blue-600"
                      : ""
                  }`}
                >
                  Residential
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("Apartment")}
                  className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 ${
                    selectedTab === "Apartment"
                      ? "bg-gray-700 text-blue-600"
                      : ""
                  }`}
                >
                  Apartment
                </button>

                <button
                  type="button"
                  onClick={() => setSelectedTab("Center & Mall")}
                  className={`hidden text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 ${
                    selectedTab === "Center & Mall"
                      ? "bg-gray-700 text-blue-600"
                      : ""
                  }`}
                >
                  Center & Mall
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("Hotel & Resorts")}
                  className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 ${
                    selectedTab === "Hotel & Resorts"
                      ? "bg-gray-700 text-blue-600"
                      : ""
                  }`}
                >
                  Hotel & Resorts
                </button>
              </div>
              <div class="relative p-4 m-4 columns-1 md:columns-2 xl:columns-3 gap-7 ">
                {filteredImages.map((image, index) => (
                  <figure
                    key={index}
                    className="relative break-inside-avoid mb-8 group" // Add the group class here
                  >
                    <img
                      className="cursor-pointer transition-all duration-700 hover:scale-110 h-auto max-w-full rounded-lg"
                      src={image.src}
                      alt={image.alt}
                    />
                    <figcaption>
                      <div className="cursor-pointer absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <p className="text-white p-2 font-medium capitalize mb-2">
                          {image.name}
                        </p>
                        <NavLink state={{ image }} to="/View">
                          <button className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            View Detail
                          </button>
                        </NavLink>
                      </div>
                    </figcaption>
                  </figure>
                ))}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
