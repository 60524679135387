import Header from "./Header";
import Footer from "./Footer";
import image1 from "../images/landsacepe.jpg";

export default function Videos({ embedId }) {
  return (
    <div>
      <div>
        <Header />
        <div className="h-full bg-gray-100">
          <div className="2xl:container 2xl:mx-auto lg:py-12 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div className="flex flex-col lg:flex-row justify-between gap-8">
              <div className="w-full lg:w-5/12 flex flex-col justify-center">
                <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                  Sample Videos
                </h1>
                <p className="font-normal text-base leading-6 text-gray-600">
                  Explore our latest modern projects. The video highlights our
                  innovative designs, sustainable practices, and attention to
                  detail in creating comfortable, stylish homes. Witness the
                  seamless integration of indoor and outdoor spaces, and the use
                  of natural light and materials to enhance living environments.
                </p>
              </div>
              <div className="w-full lg:w-8/12">
                <div className="bg-white p-4 rounded-xl shadow-lg">
                  <div className="flex flex-wrap justify-center -mx-2">
                    <div className="w-full md:w-1/2 p-2">
                      <div className="aspect-w-16 aspect-h-9">
                        <iframe
                          className="w-full h-full rounded-lg transition-transform transform hover:scale-105"
                          src="https://youtube.com/embed/ubnoT-DZxPc"
                          title="YouTube video 1"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 p-2">
                      <div className="aspect-w-16 aspect-h-9">
                        <iframe
                          className="w-full h-full rounded-lg transition-transform transform hover:scale-105"
                          src="https://www.youtube.com/embed/qVmY6pI-W44"
                          title="YouTube video 2"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
