//import backgroundImage from "../images/specal2.jpg";
import backgroundImage from "../images/new01.jpg";
import Header from "./Header";

export default function LandingImage() {
  return (
    <div>
      <div>
        <div
          className="bg-cover bg-center h-screen"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
          }}
        >
          <Header />
          <div className="h-full">
            <div className="container mx-auto flex flex-col items-center py-12 sm:py-24">
              <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-gray-800 font-black leading-7 md:leading-10">
                  Dreams Made Into{" "}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-indigo-900 via-green-700 from-[#09313d]">
                    Reality{" "}
                  </span>{" "}
                </h1>
                <p className="p-4  rounded text-black capitalize mt-5 sm:mt-10 lg:w-10/12 font-semibold text-center text-sm sm:text-xl">
                  Discover how we turn dreams into reality. Explore our services
                  and start envisioning your perfect space today
                </p>
              </div>
              <div className="flex justify-center items-center">
                <button className="ml-4 sm:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white bg-[#09313d] transition duration-150 ease-in-out  hover:bg-[#09313d] hover:border-gray-50 lg:text-xl lg:font-bold  hover:text-gray-50 rounded-lg border border-gray-50 text-gray-50 px-4 sm:px-10 py-2 sm:py-2 text-sm">
                  <a href="/portfolio">View Gallery</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
