import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import image1 from "../images/salon.png";
import image2 from "../images/cavinate1.png";
import image3 from "../images/bedro.png";
import image4 from "../images/bath2.png";
import image5 from "../images/7.5.jpg";

export default function Service() {
  const [mainImage, setMainImage] = useState(image1);
  const handleImageClick = (newImage) => {
    setMainImage(newImage);
  };

  return (
    <div>
      <Header />
      <div className="h-full">
        <div className="2xl:container 2xl:mx-auto lg:py-2 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <div className="flex flex-col lg:flex-row justify-between gap-8">
            <div className="w-full lg:w-5/12 flex flex-col justify-center">
              <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                Interior Design
              </h1>
              <p className="font-normal text-base leading-6 text-gray-600 ">
                Our Interior design services blend creativity, functionality,
                and elegance to transform spaces into stunning environments. We
                focus on creating harmonious interiors that reflect your
                personality and lifestyle, from residential homes to commercial
                spaces. With an eye for detail and a commitment to quality, we
                ensure every element is meticulously crafted. Explore our
                portfolio to see how we turn interiors into inspiring living and
                working spaces.
              </p>
            </div>
            <div className="w-full lg:w-8/12 ">
              <div>
                <div className="bg-white pb- rounded-xl">
                  <div>
                    <div>
                      <div className="max-w-3xl mx-auto ">
                        <div className="grid gap-2 px-2 mx-4">
                          <figure className="relative max-w-2xl transition-all duration-300 cursor-pointer">
                            <img
                              className=" max-w-full rounded-lg mx-auto"
                              src={mainImage}
                              alt=""
                            />
                            <figcaption class="absolute px-4 text-lg text-white bottom-6">
                              <p>Click Below Images To See </p>
                            </figcaption>
                          </figure>
                          <div className="grid grid-cols-5 gap-4 max-w-2xl">
                            <div>
                              <img
                                className="h-auto max-w-full rounded-lg"
                                src={image2}
                                alt=""
                                onClick={() => handleImageClick(image2)}
                              />
                            </div>
                            <div>
                              <img
                                className="h-auto max-w-full rounded-lg"
                                src={image3}
                                alt=""
                                onClick={() => handleImageClick(image3)}
                              />
                            </div>
                            <div>
                              <img
                                className="h-auto max-w-full rounded-lg"
                                src={image4}
                                alt=""
                                onClick={() => handleImageClick(image4)}
                              />
                            </div>
                            <div>
                              <img
                                className="h-auto max-w-full rounded-lg"
                                src={image5}
                                alt=""
                                onClick={() => handleImageClick(image5)}
                              />
                            </div>
                            <div>
                              <img
                                className="h-auto max-w-full rounded-lg"
                                src={image1}
                                alt=""
                                onClick={() => handleImageClick(image1)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
