import Body from "./Body";
import LandingImage from "./LandingImage";
import Slider from "./Slider";

export default function Home() {
  return (
    <div>
      <LandingImage />
      <Slider />
      <Body />
    </div>
  );
}
